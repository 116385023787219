<template>
  <div style=" min-height: 100vh;height: auto;">
    <div class="header">
      <van-nav-bar
        :title="$t('C2CDeposit')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div style="padding-top: 55px;">
      <div class="itemV">
        <span class="itemTv1">{{ $t("money") }}</span>
        <span class="itemTv2">{{ orderDetails.price }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("identity.regions") }}</span>
        <span v-if="orderDetails && lang === 'en'" class="itemTv2">{{
          orderDetails.country[0].enname
        }}</span>
        <span v-if="orderDetails && lang === 'ru'" class="itemTv2">{{
          orderDetails.country[0].runame
        }}</span>
        <span v-if="orderDetails && lang === 'zh'" class="itemTv2">{{
          orderDetails.country[0].cnname
        }}</span>
        <span v-if="orderDetails && lang === 'fr'" class="itemTv2">{{
          orderDetails.country[0].frname
        }}</span>
        <span v-if="orderDetails && lang === 'it'" class="itemTv2">{{
          orderDetails.country[0].itname
        }}</span>
        <span v-if="orderDetails && lang === 'es'" class="itemTv2">
          {{ orderDetails.country[0].esname }}
        </span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("fbuy.orderno") }}</span>
        <span class="itemTv2">{{ orderDetails.order_sn }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.order_sn"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("BankName") }}</span>
        <span class="itemTv2">{{ orderDetails.bank_name }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.bank_name"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("PayeeName") }}</span>
        <span class="itemTv2">{{ orderDetails.pay_name }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.pay_name"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("BankCardNumber") }}</span>
        <span class="itemTv2">{{ orderDetails.bank_number }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.bank_number"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("BankCode") }}</span>
        <span class="itemTv2">{{ orderDetails.bank_code }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.bank_code"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("RoutingNumber") }}</span>
        <span class="itemTv2">{{ orderDetails.route_code }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.route_code"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("swiftCode") }}</span>
        <span class="itemTv2">{{ orderDetails.swift }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.swift"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("BankAddress") }}</span>
        <span class="itemTv2">{{ orderDetails.bank_address }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.bank_address"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("BranchNumber") }}</span>
        <span class="itemTv2">{{ orderDetails.branch_bank_code }}</span>
        <img
          class="itemIv"
          src="../../assets/img/copy.svg"
          @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.branch_bank_code"
        />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("Remarks") }}</span>
        <span class="itemTv2">{{ orderDetails.remark }}</span>
      </div>
      <div class="imageV">
        <span class="imageTv">{{ $t("TransactionScreenshot") }}</span>
        <van-uploader :after-read="afterRead">
          <div class="carmV">
            <img
              v-if="!imageUrl"
              class="carmIv"
              src="../../assets/img/xiangji.svg"
            />
            <span v-if="!imageUrl" class="carmTv">{{ $t("UploadImage") }}</span>

            <img
              :src="imageUrl ? imageUrl : ''"
              ref="imageUrl"
              v-if="imageUrl"
              style="max-width:100%;max-height:10rem;text-align:center"
            />
          </div>
        </van-uploader>
      </div>
      <div style="margin-left: 1rem; margin-right: 1rem;">
        <div class="subTv" @click="onSubClick()">
          {{ $t("common.submit") }}
        </div>
      </div>
    </div>
    <van-popup
      :close-on-click-overlay="false"
      v-model="showDialog"
      style="background-color:transparent; overflow:hidden;"
    >
      <div class="loadingWrap">
        <van-loading v-if="showDialog" type="spinner" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      lang: localStorage.getItem("lang"),
      id: "",
      orderDetails: "",
      imageUrl: "",
      showDialog: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.onLoad();
  },
  methods: {
    async onLoad() {
      const { data } = await this.$http.get(
        "/home/user/rechargeCInfo?id=" + this.id
      );
      if (data) {
        if (data.code === 200) {
          this.orderDetails = data.data;
        }
      }
    },
    // 图片上传
    async afterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      const { data } = await this.$http.post("/home/user/uploadimg", param);
      if (data) {
        if (data.code === 200) {
          this.imageUrl = data.data.url;
        } else {
          this.$toast(data.msg);
        }
      }
    },
    onCopyClick() {
      const clipboard = new Clipboard(".itemIv");
      clipboard.on("success", (e) => {
        this.$toast.success(this.$t("common.success"));
      });
      clipboard.on("error", (e) => {
        console.log("------", JSON.stringify(e));
      });
    },
    async onSubClick() {
      if (!this.imageUrl) {
        this.$toast(this.$t("PleaseSelectAPicture"));
        return;
      }
      this.showDialog = true;
      let postform = {
        id: this.id,
        img: this.imageUrl,
      };
      const { data } = await this.$http.post(
        "/home/user/rechargeCImg",
        postform
      );
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("czhi.submitok"));
          this.$router.go(-1);
        } else {
          this.$message.error(this.$t("common.error"));
        }
      }
      this.showDialog = false;
    },
  },
};
</script>
<style lang="less" scoped>
.imageV {
  display: flex;
  padding-left: 16px;
  padding-top: 20px;
  border-top: 0.1px solid #f7f8fa;
}
.imageTv {
  font-size: 14px;
  color: #aaa;
}
.img-btn {
  font-size: 1.6rem;
  color: #999;
  padding: 3px 8px;
}
.subTv {
  width: 100%;
  height: 43px;
  background-color: #f0b82d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 33px;
}
.carmV {
  width: 80px;
  height: 80px;
  background: #f7f8fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
.carmIv {
  width: 22px;
  height: 22px;
}
.carmTv {
  color: #858a8f;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
}
.itemV {
  display: flex;
  align-items: center;
  border-top: 0.1px solid #f7f8fa;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.itemTv1 {
  color: #9097a7;
  font-size: 15px;
}
.itemTv2 {
  color: #4d5260;
  font-size: 15px;
  flex: 1;
  text-align: end;
}
.itemIv {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
</style>
